import Link from 'next/link'
import { useContext } from 'react'
import { MainContext } from '../../contexts/MainContext'
import { useRouter } from 'next/router'

const DesktopNav = () => {
	const { setIsLoading, redirectTo } = useContext(MainContext)
	const router = useRouter()
	const handleClick = (e: any) => {
		if (!(e.ctrlKey || e.metaKey)) {
			setIsLoading(true)
		}
	}

	return (
		<>
			<div className='nav-links-container-desk hidden'>
				<ul className='nav-link-wrapper flex'>
					<li className={`mr-6 relative ${router.pathname === '/' ? 'active-nav' : ''}`}>
						{router.pathname === '/' ? (
							<span> Home</span>
						) : (
							<Link href={'/'}>
								<a onClick={handleClick}> Home</a>
							</Link>
						)}
					</li>

					<li className={`mr-6 relative cursor-pointer mega-menu-links-container ${router.pathname === '/about-us' ? 'active-nav' : ''}`}>
						<div className='mega-menu-links-wrapper-desk text-gray-600 flex'>
							<span className='mr-1'>About Us</span>
							<svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4' fill='none' viewBox='0 0 24 24' stroke='#aaa'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
							</svg>
						</div>
						<div className='mega-menu-links-inner-wrapper flex flex-col absolute top-12 left-0 z-20 bg-white rounded-lg shadow-lg border border-gray-300 border-solid opacity-0 invisible'>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg  '>
								{router.asPath === '/about-us' ? (
									<span className='p-4 inline-block w-full'>About Us</span>
								) : (
									<Link href={'/about-us'}>
										<a className='p-4 inline-block w-full'>
											<div className='cursor-pointer' onClick={handleClick}>
												About Us
											</div>
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg '>
								{router.asPath === '/our-mission' ? (
									<span className='p-4 inline-block w-full'>Our Mission</span>
								) : (
									<Link href={'/our-mission'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											Our Mission
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/payment-info' ? (
									<span className='p-4 inline-block w-full'>Payment Info</span>
								) : (
									<Link href={'/payment-info'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											Payment Info
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/shipping-information' ? (
									<span className='p-4 inline-block w-full'> Shipping Information</span>
								) : (
									<Link href={'/shipping-information'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											{' '}
											Shipping Information
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/warranty' ? (
									<span className='p-4 inline-block w-full'>Warranty</span>
								) : (
									<Link href={'/warranty'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											Warranty
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/return-policy' ? (
									<span className='p-4 inline-block w-full'>Return Policy</span>
								) : (
									<Link href={'/return-policy'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											Return Policy
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/returns-refunds-and-exchanges' ? (
									<span className='p-4 inline-block w-full'>Shipping &amp; Return</span>
								) : (
									<Link href={'/returns-refunds-and-exchanges'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											Shipping &amp; Return
										</a>
									</Link>
								)}
							</div>

							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/repair-products' ? (
									<span className='p-4 inline-block w-full'>Repair Products</span>
								) : (
									<Link href={'/repair-products'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											Repair Products
										</a>
									</Link>
								)}
							</div>

							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/trade-shows' ? (
									<span className='p-4 inline-block w-full'>Trade Shows</span>
								) : (
									<Link href={'/trade-shows'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											Trade Shows
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/faqs' ? (
									<span className='p-4 inline-block w-full'>FAQs</span>
								) : (
									<Link href={'/faqs'}>
										<a className='p-4 inline-block w-full' onClick={() => setIsLoading(true)}>
											FAQs
										</a>
									</Link>
								)}
							</div>
							<div className='relative mega-menu-link border-b border-solid border-gray-300 rounded-lg'>
								{router.asPath === '/contact' ? (
									<span className='p-4 inline-block w-full'>Contact Us</span>
								) : (
									<Link href={'/contact'}>
										<a className='p-4 inline-block w-full' onClick={handleClick}>
											Contact Us
										</a>
									</Link>
								)}
							</div>
						</div>
					</li>
					<li className={`mr-6 relative cursor-pointer ${router.pathname === '/downloads' ? 'active-nav' : ''}`}>
						{router.asPath === '/downloads' ? (
							<span>Downloads</span>
						) : (
							<Link href={'/downloads'}>
								<a onClick={handleClick}>Downloads</a>
							</Link>
						)}
					</li>
					<li className={`mr-6 relative cursor-pointer   ${router.pathname === '/videos' ? 'active-nav' : ''}`}>
						{router.asPath === '/videos' ? (
							<span> Videos</span>
						) : (
							<Link href={'/videos'}>
								<a onClick={() => setIsLoading(true)}> Videos</a>
							</Link>
						)}
					</li>
					<li className={`mr-6 relative cursor-pointer ${router.pathname === '/blog' ? 'active-nav' : ''}`}>
						{router.asPath === '/blog' ? (
							<span>Blog</span>
						) : (
							<Link href={'/blog'}>
								<a onClick={handleClick}>Blog</a>
							</Link>
						)}
					</li>

					<li className={`relative cursor-pointer mr-6 ${router.pathname === '/show-special/greater-ny-dental-meeting' ? 'active-nav' : ''}`}>
						{router.asPath === '/show-special/greater-ny-dental-meeting' ? (
							<span>Show Special</span>
						) : (
							<Link href={'/show-special/greater-ny-dental-meeting'}>
								<a onClick={handleClick}>Show Special</a>
							</Link>
						)}
					</li>

					{/* <li className={`relative cursor-pointer mr-6 ${router.pathname === '/trade-shows' ? 'active-nav' : ''}`}>
						{router.asPath === '/trade-shows' ? (
							<span>Trade Shows</span>
						) : (
							<Link href={'/trade-shows'}>
								<a onClick={handleClick}>Trade Shows</a>
							</Link>
						)}
					</li> */}

					<li className={`relative cursor-pointer mr-6 ${router.pathname === '/dental-surgical-instruments' ? 'active-nav' : ''}`}>
						{router.asPath === '/dental-surgical-instruments' ? (
							<span>Our Instruments</span>
						) : (
							<Link href={'/dental-surgical-instruments'}>
								<a onClick={handleClick}>Our Instruments</a>
							</Link>
						)}
					</li>

					<li className={`relative cursor-pointer ${router.pathname === '/special-discount-offers' ? 'active-nav' : ''}`}>
						{router.asPath === '/special-discount-offers' ? (
							<span>Special Offers</span>
						) : (
							<Link href={'/special-discount-offers'}>
								<a onClick={handleClick}>Special Offers</a>
							</Link>
						)}
					</li>
				</ul>
			</div>
		</>
	)
}

export default DesktopNav
